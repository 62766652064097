import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
  static values  = { checkedAll: String, checkedEmpty: String, checked: String }
  static targets = [ "label" ]

  connect() {
    super.connect()
    if ( this.checkedEmptyValue == "" ) {
      this.checkedEmptyValue = this.checkedAllValue
    }
    this._setLabel()
  }

  change(event) {
    this._setLabel()
  }

  _setLabel() {
    let checkElements = this.menuTarget.querySelectorAll('input[type=checkbox]')
    let checkedElements = this.menuTarget.querySelectorAll('input[type=checkbox]:checked')
    if ( checkElements.length == checkedElements.length ) {
      this.labelTarget.innerHTML = this.checkedAllValue.replace(/%d/, checkedElements.length)
    } else if ( checkedElements.length == 0 ) {
      this.labelTarget.innerHTML = this.checkedEmptyValue.replace(/%d/, checkedElements.length)
    } else if ( checkedElements.length > 0 ) {
      this.labelTarget.innerHTML = this.checkedValue.replace(/%d/, checkedElements.length)
    }
  }
}
