import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "word", "search"]

  scroll(e) {
    const selectedCategoryId = e.target.value;
    if (selectedCategoryId) {
      const categoryElement = document.getElementById(`category-${selectedCategoryId}`);
      if (categoryElement) {
        const elementPosition = categoryElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 100;  // ヘッダー分100pxほど上にずらす

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    } else {
      // すべてのカテゴリーが選択された場合、ページの先頭にスクロール
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  is_empty(e) {
    if(this.wordTarget.value.trim().length == 0){
      e.preventDefault()
      e.stopPropagation()
    }
  }

  submitWithKeydown(e) {
    if (e.keyCode == 13) {
      this.submit(e)
    }
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()
    if(this.wordTarget.value.trim().length == 0){
      return
    }
    let url  = this.formTarget.action
    let data = new FormData(this.formTarget)
    let currentUrl = location.pathname
    let urlObj = new URL(url)

    urlObj.search = new URLSearchParams(data)
    this.searchTarget.innerHTML = this.wordTarget.value

    const dispatchEvent = new CustomEvent('search', { detail: { url: urlObj } })
    window.dispatchEvent(dispatchEvent)
  }

  toggle(e) {
    e.currentTarget.classList.toggle("is-active")
    e.currentTarget.nextElementSibling.classList.toggle("is-open")
  }
}
