import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "form", "list"]
  static values  = { locked: Boolean, fetchRankingUrl: String }

  fetch(e) {
    e.preventDefault()
    e.stopPropagation()
    const url =this.fetchRankingUrlValue
    if (!url) {
      return
    }
    const data = new FormData()
    data.append('ranking_group_id', e.currentTarget.value)
    const urlObj = new URL(url)
    urlObj.search = new URLSearchParams(data)
    fetch(urlObj.href)
      .then( res => res.json() )
      .then( json => {
        this.listTarget.innerHTML = json.entries
	this.submit(e)
    })

  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()

    let url  = this.formTarget.action
    let data = new FormData(this.formTarget)
    let currentUrl = location.pathname
    let urlObj = new URL(url)
    if (currentUrl.replace(/\/$/,"") != urlObj.pathname) {
      return
    }
    urlObj.search = new URLSearchParams(data)

    const dispatchEvent = new CustomEvent('search', { detail: { url: urlObj } })
    window.dispatchEvent(dispatchEvent)
  }

  my() {
    let url  = this.formTarget.action
    let data = new FormData(this.formTarget)
    let currentUrl = location.pathname
    let tmpUrlObj = new URL(url)
    if (currentUrl.replace(/\/$/,"") != tmpUrlObj.pathname) {
      return
    }
    let urlObj = new URL(url.replace('/ranking', '/ranking/my'))
    urlObj.search = new URLSearchParams(data)

    fetch(urlObj.href)
      .then( res => res.json() )
      .then( json => {
        this.entriesTarget.innerHTML = json.entries
      })
  }

}
