import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'

export default class extends Controller {

  static values = { spaceBetween: { type: Number, default: 140 } }
  static targets = ["background"]

  connect() {
    this._setSlides()
  }

  /* setup slides */
  _setSlides() {
    let containerEl = document.querySelector('.slides .swiper')
    let currentBgEl = document.createElement('img')
    let nextBgEl    = document.createElement('img')
    if (containerEl == undefined || currentBgEl == undefined || nextBgEl == undefined) {
      return
    }

    let firstImageTag = containerEl.querySelector('.swiper-slide img')
    if (firstImageTag === undefined) return
    currentBgEl.src = firstImageTag.src

    this.backgroundTarget.append(currentBgEl)
    this.backgroundTarget.append(nextBgEl)

    let swiper = new Swiper(containerEl, {
      modules: [ Navigation, Pagination, Autoplay ],
      slidesPerView: "auto",
      spaceBetween: 20,
      loop: true,
      loopAdditionalSlides: 1,
      centeredSlides: true,
      freeMode: {
        enabled: false,
        sticky: true,
      },
      autoplay: {
        delay: 7500,
        disableOnInteraction: false,
        waitForTransition: false
      },
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'swiper-pagination-bullet'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // 760px以上
        760: {
          spaceBetween: this.spaceBetweenValue,
        }
      }
    })

    // Fade in the next background image
    swiper.on('slideChange', (we) => {
      let activeImageTag = we.slides[we.activeIndex].querySelector('img')
      let nextIndex      = ( we.slides.length - 1 < we.activeIndex + 1 ) ? 0 : we.activeIndex
      let nextImageTag   = we.slides[nextIndex].querySelector('img')
      let duration       = 500

      nextBgEl.style.opacity = 0
      nextBgEl.src = nextImageTag.src
      nextBgEl.animate(
        [
          { opacity: 0 },
          { opacity: 1 },
        ],
        {
          duration: duration,
          fill: 'forwards'
        }
      )
      setTimeout(() => {
       currentBgEl.src = nextBgEl.src
       nextBgEl.style.opacity = 0
      }, duration)
    })
  }

}
