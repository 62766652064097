import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import _ from 'lodash';
_.templateSettings.escape = /{{([\s\S]+?)}}/g;

export default class extends Controller {
  static values = {
    login: Boolean,
    deckDetailUrl: String,
    cardDetailUrl: String,
    deckListUrl: String,
    deckDefaultName: String,
    deck: Object,
    deckCards: Object,
    deckSubCards: Object,
    tempVersionKey: {
      type: String,
      default: '9322a554221d7f0803d2605d0b26e89c',
    },
    deckStructures: {
      type: Object,
      default: {
        minSize: 41,
        maxSize: 51,
        tmpMaxSize: 70,
        territory: 1,
        baster: 12,
        shot: 12,
        limitSize: 4,
      },
    },
    selectedMain: { type: Boolean, default: true },
  };
  static targets = [
    'entries',
    'deckName',
    'deckList',
    'playerDeckCardTemplate',
    'searchCard',
    'totalSize',
    'deckSubSize',
    'territorySize',
    'basterSize',
    'shotSize',
    'maxSize',
    'minSize',
    'maxDeckSubSize',
    'maxTerritorySize',
    'maxBasterSize',
    'maxShotSize',
    'newDeck',
    'newDeckName',
    'newDeckCode',
    'cardDetail',
    'published',
  ];

  swiper = null;

  connect() {
    let value = this.getTemporary();
    if (value != null) {
      this.deckValue = value.deck;
      this.deckCardsValue = value.deckCards;
      this.deckSubCardsValue = value.deckSubCards;
    }

    if (this.loginValue) {
      this._fetchDeckList();
    }

    this.show();

    if ('id' in this.deckValue && this.deckValue.id != null) {
      this._fetchDeckDetail(this.deckValue.id);
    }

    this.swiper = new Swiper('.swiper', {
      slidesPerView: 'auto',
    });
  }

  _fetchDeckList() {
    if (this.deckListUrlValue == '') {
      return;
    }

    fetch(this.deckListUrlValue)
      .then((res) => res.text())
      .then((html) => {
        this.deckListTarget.innerHTML = html;
        this.swiper.update();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  _fetchDeckDetail(id, notify = false) {
    if (this.deckDetailUrlValue == '') {
      return;
    }
    let url = this.deckDetailUrlValue + '/' + id;
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        // indexing
        let cards = json.cards,
          sub_cards = json.sub_cards,
          deckCards = {},
          deckSubCards = {};

        Object.keys(cards).forEach((k) => {
          cards[k].limit_count = this.deckStructuresValue.limitSize;
          cards[k].unit_count = this.deckStructuresValue.limitSize;
          deckCards[cards[k].card_id] = cards[k];
        });

        Object.keys(sub_cards).forEach((k) => {
          sub_cards[k].limit_count = this.deckStructuresValue.limitSize;
          sub_cards[k].unit_count = this.deckStructuresValue.limitSize;
          deckSubCards[sub_cards[k].card_id] = sub_cards[k];
        });

        this.deckCardsValue = deckCards;
        this.deckSubCardsValue = deckSubCards;
        delete json.cards;
        delete json.sub_cards;
        this.deckValue = json;
        this.selectedMainValue = true;

        // rendered
        this.show();

        if (this.hasPublishedTarget && this.loginValue) {
          this.publishedTarget.checked = json.tags.includes(this.publishedTarget.value) ? true : false;
        }

        if (notify) {
          window.alert('選択しているデッキを変更しました');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  _clearDeckDetail() {
    this.deckCardsValue = {};
    this.deckSubCardsValue = {};
    this.deckValue = {};
  }

  _validateBeforeAppend(deckCard, deckSubCard) {
    let id;
    if (deckCard) {
      id = deckCard.card_id;
      // init limit_count, unit_count
      deckCard.limit_count = this.deckStructuresValue.limitSize;
      deckCard.union_count = this.deckStructuresValue.limitSize;
    }
    if (deckSubCard) {
      id = deckSubCard.card_id;
      // init limit_count, unit_count
      deckSubCard.limit_count = this.deckStructuresValue.limitSize;
      deckSubCard.union_count = this.deckStructuresValue.limitSize;
    }
    if (!id) {
      return false;
    }

    let deckCards = _.cloneDeep(this.deckCardsValue),
      deckSubCards = _.cloneDeep(this.deckSubCardsValue),
      totalSize = 0,
      territorySize = 0,
      basterSize = 0,
      shotSize = 0,
      deckSubSize = 0,
      effect_enforce_limit = null,
      effect_change_limit = null;

    if (Object.keys(deckCards).length > 0) {
      Object.keys(deckCards).forEach((cardId) => {
        if (deckCards[cardId].territory) {
          territorySize = territorySize + deckCards[cardId].size;
        }
        if (deckCards[cardId].baster) {
          basterSize = basterSize + deckCards[cardId].size;
        }
        if (deckCards[cardId].shot) {
          shotSize = shotSize + deckCards[cardId].size;
        }
        totalSize = totalSize + deckCards[cardId].size;

        // has_enforce_limit?
        deckCards[cardId].effects.forEach((effect) => {
          switch (effect.effect) {
            case 'ENFORCE_LIMIT':
              effect_enforce_limit = effect;
              return;
          }
        });
      });
    }

    // メインデッキチェック
    if (deckCard != null) {
      if (totalSize >= this.deckStructuresValue.tmpMaxSize) {
        window.alert('一時的にデッキの登録できるカードの上限を超えています');
        return false;
      }

      if (deckCard.territory && territorySize >= this.deckStructuresValue.territory) {
        window.alert('テリトリーカードの設定上限を超えています');
        return false;
      }

      let has_change_limit = false;
      // adjust by change_limit
      deckCard.effects.forEach((effect) => {
        switch (effect.effect) {
          case 'FIX_STRUCTURE':
            const c = effect.value.value.filter((v) => {
              return v.card_id == deckCard.card_id;
            });
            // Changed so that FIX_STRUCTURE can register up to the required card size
            if (c[0]) {
              deckCard.limit_count = c[0].size;
            }
            return;
          case 'UNION_LIMIT':
	    if(deckSubCards[deckCard.card_id] && deckSubCards[deckCard.card_id].size > 0) {
              deckCard.union_count = effect.value.value;
	    }
            return;
          case 'MODIFY_LIMIT':
            deckCard.limit_count = effect.value.value;
            deckCard.union_count = effect.value.value;
            return;
          case 'CHANGE_LIMIT':
            deckCard.limit_count = effect.value.value;
            deckCard.union_count = effect.value.value;
            has_change_limit = true;
            return;
        }
      });

      // adjust by effect_enforce_limit
      if (
	!has_change_limit &&
        effect_enforce_limit &&
        (!effect_enforce_limit.value.card_id || effect_enforce_limit.value.card_id == deckCard.card_id)
      ) {
        // CHANGE_LIMIT持ちはlimit_countの更新はしない
        if (effect_enforce_limit.value.value.card_id && effect_enforce_limit.value.value.card_id == deckCard.card_id) {
          deckCard.limit_count = effect_enforce_limit.value.value.size;
          deckCard.union_count = effect_enforce_limit.value.value.size;
        } else if (!effect_enforce_limit.value.value.card_id) {
          deckCard.limit_count = effect_enforce_limit.value.value.size;
          deckCard.union_count = effect_enforce_limit.value.value.size;
        }
      }

      const card_size = deckCards[deckCard.card_id] ? deckCards[deckCard.card_id].size : 0;
      if (card_size >= deckCard.limit_count) {
        window.alert('このカードは ' + deckCard.limit_count + ' 枚まで設定できます');
        return false;
      }

      if (deckSubCards[deckCard.card_id] && deckSubCards[deckCard.card_id].size >= deckCard.union_count) {
        window.alert('このカードは Ｄデッキ を含め' + deckCard.union_count + ' 枚まで設定できます');
        return false;
      }

      if (
        deckCards[deckCard.card_id] &&
        deckSubCards[deckCard.card_id] &&
        deckCards[deckCard.card_id].size + deckSubCards[deckCard.card_id].size >= deckCard.union_count
      ) {
        window.alert('このカードは Ｄデッキ を含め' + deckCard.union_count + ' 枚まで設定できます');
        return false;
      }
    }

    // Dデッキチェック
    if (deckSubCard != null) {

      let has_change_limit = false;

      Object.keys(deckSubCards).forEach((cardId) => {
        // init limit_count, unit_count
        deckSubCards[cardId].limit_count = this.deckStructuresValue.limitSize;
        deckSubCards[cardId].unit_count = this.deckStructuresValue.limitSize;
        deckSubSize = deckSubSize + deckSubCards[cardId].size;
      });
      if (deckSubSize >= this.deckStructuresValue.subMaxSize) {
        window.alert('Ｄデッキに登録できるカードの上限を超えています');
        return false;
      }

      // adjust by union_limit
      deckSubCard.effects.forEach((effect) => {
        switch (effect.effect) {
          case 'UNION_LIMIT':
            deckSubCard.union_count = effect.value.value;
            return;
          case 'MODIFY_LIMIT':
            deckSubCard.limit_count = effect.value.value;
            deckSubCard.union_count = effect.value.value;
            return;
          case 'CHANGE_LIMIT':
            deckSubCard.limit_count = effect.value.value;
            deckSubCard.union_count = effect.value.value;
            has_change_limit = true;
            return;
        }
      });

      // adjust by effect_enforce_limit
      if (
	!has_change_limit &&
        effect_enforce_limit &&
        (!effect_enforce_limit.value.card_id || effect_enforce_limit.value.card_id == deckSubCard.card_id)
      ) {
        // CHANGE_LIMIT持ちは更新はしない
        if (effect_enforce_limit.value.value.card_id && effect_enforce_limit.value.value.card_id == deckSubCard.card_id) {
          deckSubCard.limit_count = effect_enforce_limit.value.value.size;
	  deckSubCard.union_count = effect_enforce_limit.value.value.size;
        } else if (!effect_enforce_limit.value.value.card_id) {
          deckSubCard.limit_count = effect_enforce_limit.value.value.size;
          deckSubCard.union_count = effect_enforce_limit.value.value.size;
        }
      }

      const card_size = deckSubCards[deckSubCard.card_id] ? deckSubCards[deckSubCard.card_id].size : 0;
      if (card_size >= deckSubCard.union_count) {
        window.alert('このカードは ' + deckSubCard.union_count + ' 枚まで設定できます');
        return false;
      }

      if (deckCards[deckSubCard.card_id] && deckCards[deckSubCard.card_id].size >= deckSubCard.union_count) {
        window.alert('このカードは メインデッキ を含め' + deckSubCard.union_count + ' 枚まで設定できます');
        return false;
      }
      if (
        deckCards[deckSubCard.card_id] &&
        deckSubCards[deckSubCard.card_id] &&
        deckCards[deckSubCard.card_id].size + deckSubCards[deckSubCard.card_id].size >= deckSubCard.union_count
      ) {
        window.alert('このカードは メインデッキ を含め' + deckSubCard.union_count + ' 枚まで設定できます');
        return false;
      }
    }

    return true;
  }

  append(e) {
    let deckCards = this.selectedMainValue ? this.deckCardsValue : this.deckSubCardsValue,
      deckCard = e.params.playerDeckCard,
      cardId = e.params.playerDeckCard.card_id;

    if (cardId in deckCards) {
      deckCards[cardId].size = deckCards[cardId].size + 1;
    } else {
      deckCards[cardId] = deckCard;
      deckCards[cardId].limit_count = this.deckStructuresValue.limitSize;
    }

    if (this.selectedMainValue && this._validateBeforeAppend(deckCards[cardId], null)) {
      this.deckCardsValue = deckCards;
    } else if (!this.selectedMainValue && this._validateBeforeAppend(null, deckCards[cardId])) {
      this.deckSubCardsValue = deckCards;
    }

    this.show();
  }

  prepend(e) {
    let deckCards = this.selectedMainValue ? this.deckCardsValue : this.deckSubCardsValue;
    if (e.params.cardId in deckCards && deckCards[e.params.cardId].size > 1) {
      deckCards[e.params.cardId].size = deckCards[e.params.cardId].size - 1;
    } else if (e.params.cardId in deckCards) {
      delete deckCards[e.params.cardId];
    }

    if (this.selectedMainValue) {
      this.deckCardsValue = deckCards;
    } else {
      this.deckSubCardsValue = deckCards;
    }

    this.show();
  }

  changeDeck(e) {
    e.preventDefault();
    this._fetchDeckDetail(e.params.deckId, true);
  }

  deckStructuresValueChanged(value, previousValue) {
    if (previousValue === undefined) {
      return;
    }

    if (previousValue.baster != value.baster) {
      this.maxBasterSizeTargets.forEach((v) => {
        v.innerHTML = value.baster;
      });
    }

    if (previousValue.shot != value.shot) {
      this.maxShotSizeTargets.forEach((v) => {
        v.innerHTML = value.shot;
      });
    }
  }

  deckValueChanged(value, previousValue) {
    if ('name' in value) {
      Object.keys(this.deckNameTargets).forEach((k) => {
        this.deckNameTargets[k].innerText = value.name;
        this.deckNameTargets[k].value = value.name;
      });
    } else {
      Object.keys(this.deckNameTargets).forEach((k) => {
        this.deckNameTargets[k].innerText = this.deckDefaultNameValue;
        this.deckNameTargets[k].value = this.deckDefaultNameValue;
      });
    }
  }

  deckNameChanged(e) {
    let id = this.deckValue.id;
    this.deckValue = {
      id: id,
      name: e.currentTarget.value,
    };
  }

  searchCardTargetConnected(target) {
    if (this.selectedMainValue) {
      target.innerHTML =
        target.dataset.cardId in this.deckCardsValue ? this.deckCardsValue[target.dataset.cardId].size : 0;
    } else {
      target.innerHTML =
        target.dataset.cardId in this.deckSubCardsValue ? this.deckSubCardsValue[target.dataset.cardId].size : 0;
    }
  }

  detail(e) {
    e.preventDefault();

    fetch(this.cardDetailUrlValue + '/' + e.params.cardId + '.json')
      .then((res) => res.json())
      .then((json) => {
        this.cardDetailTarget.innerHTML = json.contents;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  new(e) {
    e.preventDefault();
    fetch(e.currentTarget.href)
      .then((res) => res.text())
      .then((html) => {
        this.newDeckTarget.innerHTML = html;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  create(e) {
    e.preventDefault();
    if (this.newDeckNameTarget.value.length == 0) {
      return;
    }

    fetch(e.currentTarget.action, {
      mode: 'cors',
      method: e.currentTarget.method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.newDeckNameTarget.value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this._fetchDeckList();
        this._fetchDeckDetail(json.deck.id);

        const dispatchEvent = new CustomEvent('modal.close', {
          detail: { type: 'overlay' },
        });
        window.dispatchEvent(dispatchEvent);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  copy(e) {
    e.preventDefault();
    if (this.newDeckCodeTarget.value.length == 0) {
      return;
    }

    fetch(e.currentTarget.action, {
      mode: 'cors',
      method: e.currentTarget.method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: this.newDeckCodeTarget.value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this._fetchDeckList();
        this._fetchDeckDetail(json.deck.id);

        const dispatchEvent = new CustomEvent('modal.close', {
          detail: { type: 'overlay' },
        });
        window.dispatchEvent(dispatchEvent);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getKey() {
    return this.tempVersionKeyValue;
  }

  getTemporary() {
    try {
      let value = localStorage.getItem(this.getKey());
      return JSON.parse(value);
    } catch (e) {
      new Error(e);
    }
  }

  saveTemporary() {
    let value = {
      deck: this.deckValue,
      deckCards: this.deckCardsValue,
      deckSubCards: this.deckSubCardsValue,
    };
    try {
      localStorage.setItem(this.getKey(), JSON.stringify(value));
    } catch (e) {
      return false;
    }
    return true;
  }

  clearTemporary() {
    localStorage.removeItem(this.getKey());
  }

  switch(e) {
    this.selectedMainValue = e.params.main;
  }

  selectedMainValueChanged(value, previousValue) {
    if (previousValue === undefined || value == previousValue) {
      return;
    }
    this.show();
  }

  show() {
    let deckCard = this.selectedMainValue ? this.deckCardsValue : this.deckSubCardsValue;
    let _template = '',
      template = _.template(this.playerDeckCardTemplateTarget.innerHTML),
      totalSize = 0,
      deckSubSize = 0,
      territorySize = 0,
      basterSize = 0,
      shotSize = 0,
      searchCardTargets = {};

    this.deckStructuresValue = {
      minSize: 49,
      maxSize: 51,
      tmpMaxSize: 70,
      territory: 1,
      baster: 12,
      shot: 12,
      subMaxSize: 12,
      limitSize: 4,
    };

    // Indexes cards currently out
    Object.keys(this.searchCardTargets).forEach((k) => {
      searchCardTargets[this.searchCardTargets[k].dataset.cardId] = this.searchCardTargets[k];
    });

    // Processing of cards currently out
    if (deckCard instanceof Object) {
      // sort value
      let sortedValue = Object.values(deckCard).sort((a, b) => {
        return (
          b.territory - a.territory ||
          b.ace - a.ace ||
          b.baster - a.baster ||
          b.unit - a.unit ||
          b.shot - a.shot ||
          b.action - a.action
        );
      });

      sortedValue.forEach((v) => {
        let template_value = v;
        template_value.self = JSON.stringify(v);

        _template = _template + template(template_value);

        if (v.card_id in searchCardTargets) {
          searchCardTargets[v.card_id].innerHTML = v.size;
        }
      });
    }

    // Processing of cards included in the main deck
    Object.values(this.deckCardsValue).forEach((v) => {
      totalSize = totalSize + v.size;
      if (v.territory) {
        territorySize = territorySize + v.size;
      }
      if (v.baster) {
        basterSize = basterSize + v.size;
      }
      if (v.shot) {
        shotSize = shotSize + v.size;
      }
      if (v.card_id in searchCardTargets) {
        searchCardTargets[v.card_id].innerHTML = v.size;
      }

      // effects
      if (v.effects.length > 0) {
        v.effects.forEach((vv) => {
          if (vv.effect == 'CHANGE_CONFIGURATION') {
            if (vv.value.length <= 0) {
              return;
            }
            let deckStructures = this.deckStructuresValue;

            if ('baster' in vv.value) {
              deckStructures.baster = vv.value.baster;
            }
            if ('shot' in vv.value) {
              deckStructures.shot = vv.value.shot;
              this.deckStructuresValue.shot = vv.value.shot;
            }

            if (this.deckStructuresValue != deckStructures) {
              this.deckStructuresValue = deckStructures;
            }
          }
        });
      }
    });

    // Processing of cards contained in sub-decks
    Object.values(this.deckSubCardsValue).forEach((v) => {
      deckSubSize = deckSubSize + v.size;
    });

    this.totalSizeTarget.innerHTML = totalSize;
    this.deckSubSizeTarget.innerHTML = deckSubSize;
    this.territorySizeTarget.innerHTML = territorySize;
    this.basterSizeTarget.innerHTML = basterSize;
    this.shotSizeTarget.innerHTML = shotSize;
    this.entriesTarget.innerHTML = _template;
    if (this.searchCardTargets.length > 0) {
      if (this.selectedMainValue) {
        Object.keys(this.searchCardTargets).forEach((k) => {
          this.searchCardTargets[k].innerHTML = this.deckCardsValue[this.searchCardTargets[k].dataset.cardId]
            ? this.deckCardsValue[this.searchCardTargets[k].dataset.cardId].size
            : 0;
        });
      } else {
        Object.keys(this.searchCardTargets).forEach((k) => {
          this.searchCardTargets[k].innerHTML = this.deckSubCardsValue[this.searchCardTargets[k].dataset.cardId]
            ? this.deckSubCardsValue[this.searchCardTargets[k].dataset.cardId].size
            : 0;
        });
      }
    }
  }

  save(e) {
    e.preventDefault();

    if (!this.loginValue && !this.saveTemporary()) {
      window.alert('デッキの一時保存に失敗しました');
      return;
    }

    if (!this.loginValue) {
      window.alert(
        'デッキを保存するには「D.I.V.E.」へのログインが必要です。\nサイト下部の「D.I.V.E.（ダイブ）プレイヤーズサイト」から登録・ログインできます。'
      );
      return;
    }

    if (!('name' in this.deckValue)) {
      window.alert('デッキ名を設定してください');
      return;
    }

    fetch(e.currentTarget.action, {
      mode: 'cors',
      method: e.currentTarget.method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deck: this.deckValue,
        deck_cards: Object.values(this.deckCardsValue),
        deck_sub_cards: Object.values(this.deckSubCardsValue),
        tag: e.currentTarget.tag && e.currentTarget.tag.checked ? e.currentTarget.tag.value : null,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == 200) {
          this.deckValue = json.deck;
          this.clearTemporary();

          const currentPath = new URL(location.href);
          const basePath = location.href.replace(currentPath.search, '');

          history.pushState('', '', basePath);
          window.alert('保存しました');
          this._fetchDeckList();
        }
      })
      .catch((error) => {
        window.alert('保存できませんでした');
        console.log(error);
      });
  }

  destroy(e) {
    e.preventDefault();
    let result = window.confirm('「' + e.params.name + '」を削除してもよろしいですか？');
    if (result) {
      fetch(e.currentTarget.href, {
        mode: 'cors',
        method: e.currentTarget.dataset.method,
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((json) => {
          this._fetchDeckList();
          this._clearDeckDetail();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  image(e) {
    e.preventDefault();

    fetch(e.currentTarget.action, {
      mode: 'cors',
      method: e.currentTarget.method,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deck: this.deckValue,
        deck_cards: Object.values(this.deckCardsValue),
        deck_sub_cards: Object.values(this.deckSubCardsValue),
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        window.open(json.path);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
