import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination", "totalCount"]
  static values  = { locked: Boolean }

  connect() {
    const dispatchEvent = new CustomEvent('connectedInfiniteScroll')
    window.dispatchEvent(dispatchEvent)
  }

  init(e) {
    this.totalCountTarget.innerHTML = ""
    this.load(e.detail.url)
  }

  scroll() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }

    let url = new URL(next_page.href)

    var body = document.body,
    html = document.documentElement

    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
    if (window.pageYOffset + 100 >= height - window.innerHeight) {
      if (!this.lockedValue) {
        this.lockedValue = true
        this.loadMore(url)
      }
    }
  }

  load(url) {

    let json_url = url.pathname
    let ext = json_url.split(".").slice(-1)[0]
    if ( ext != "json" ) {
      url.pathname = json_url + ".json"
    }

    fetch(url.href)
      .then( res => res.json() )
      .then( json => {
        this.entriesTarget.innerHTML = json.entries
        this.paginationTarget.innerHTML = json.pagination
        this.totalCountTarget.innerHTML = json.count.total
        this.lockedValue = false

        window.setTimeout( () => {
          this.entriesTarget.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }, 50)
      })
      .catch( error => {
        console.log(error)
        this.lockedValue = false
      })
  }

  loadMore(url) {

    let json_url = url.pathname
    let ext = json_url.split(".").slice(-1)[0]
    if ( ext != "json" ) {
      url.pathname = json_url + ".json"
    }

    fetch(url.href)
      .then( res => res.json() )
      .then( json => {
        this.entriesTarget.insertAdjacentHTML('beforeend', json.entries)
        this.paginationTarget.innerHTML = json.pagination
        this.totalCountTarget.innerHTML = json.count.total
        this.lockedValue = false
      })
      .catch( error => {
        console.log(error)
        this.lockedValue = false
      })
  }
}
