import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    good(e) {
        e.preventDefault()
        const container = e.currentTarget.parentNode

        fetch(
            '/official/recipes/good',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    hashid: e.params.hashid
                })
            }
        )
        .then((res) => res.json())
        .then((json) => {
            container.innerHTML = json.contents
        })
        .catch((e) => {
            console.error(e)
        })
    }
}
