import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { params: Object }
  static targets = ["form", "result"]

  connect() {
    this.resultTarget.hidden = true

    this.setParams()
  }

  setParams() {
    // case checkbox
    Object.keys(this.paramsValue).forEach( ( k, index ) => {
      let elements = this.formTarget.querySelectorAll("[type=checkbox][name='" + k + "']")
      elements.forEach( ( element, i ) => {
        if (element.value == this.paramsValue[k] ) {
          element.checked = true
        }
      })
    })
  }

  connectedInfiniteScroll() {
    if (Object.keys(this.paramsValue).length > 0 ) {
      const dispatchEvent = new CustomEvent('submit')
      this.formTarget.dispatchEvent(dispatchEvent)
    }
  }

  changeSearchColorParams(e) {
    // get current checkbox element
    let checkbox = e.currentTarget.querySelector("input[type=checkbox]")
    if (checkbox == null) {
      return
    }

    // get the current checkbox group and case "All" checkboxes
    let targetCheckboxes = this.formTarget.querySelectorAll("input[type=checkbox][name='" + checkbox.name + "']")
    if (checkbox.value == "") {
      // All checkbox groups when the "All" checkbox is checked, and all when unchecked.
      targetCheckboxes.forEach( (element, i ) => {
        if (element  == checkbox) {
          return
        }
        element.checked = checkbox.checked
      })
    } else {
      // If a checkbox other than "All" is checked, check the "All" checkbox and uncheck it otherwise.
      let hasChecked    = false
      let hasNoChecked  = false
      let emptyCheckbox = null
      targetCheckboxes.forEach( (element, i ) => {
        if (element.value == "") {
          emptyCheckbox = element
        } else if (element.value != "" && element.checked) {
          hasChecked = true
        } else if (element.value != "" && !element.checked) {
          hasNoChecked = true
        }
      })

      // Return if the "All" checkbox is not found.
      if (emptyCheckbox == null) {
        return
      }

      if (hasChecked && hasNoChecked) {
        emptyCheckbox.checked = false
      } else if (!hasChecked && hasNoChecked) {
        emptyCheckbox.checked = false
      } else if (hasChecked && !hasNoChecked) {
        emptyCheckbox.checked = true
      }
      
    }
  }

  submitWithKeydown(e) {
    if (e.keyCode == 13) {
      this.submit(e)
    }
  }

  submit(e) {
    let form = e.currentTarget
    let currentUrl = location.pathname
    let url  = form.action
    let urlObj = new URL(url)

    e.preventDefault()
    e.stopPropagation()

    let data = new FormData(form)
    urlObj.search = new URLSearchParams(data)

    this.resultTarget.hidden = false;

    const dispatchEvent = new CustomEvent('search', { detail: { url: urlObj } })
    window.dispatchEvent(dispatchEvent)
  }

  reset(e) {
    e.preventDefault()

    let form = this.formTarget
    for(let elem of form) {
      switch(elem.type) {
          case "hidden":
          case "submit":
          case "reset":
          case "button":
          case "image":
            continue
          case "file":
            continue
          case "search":
          case "text":
          case "password":
          case "textarea":
            elem.value = ""
            continue
          case "checkbox":
            elem.checked = false
            continue
          case "radio":
            elem.checked = ( elem.value == "" ) ? true : false
            continue
          case "select-one":
          case "select-multiple":
            elem.selectedIndex = 0
            continue
          default:
            continue
      }
    }
    const dispatchEvent = new CustomEvent('reset', {})
    window.dispatchEvent(dispatchEvent)
  }
}
