import { application } from "../controllers/application"

import Slides from "../controllers/slides_controller"
application.register("slides", Slides)

import SlideContents from "../controllers/slide_contents_controller"
application.register("slide-contents", SlideContents)

import CheckListBox from "../controllers/check_list_box_controller"
application.register("check-list-box", CheckListBox)

import CardSearch from "../controllers/card_search_controller"
application.register("card-search", CardSearch)

import Modals from "../controllers/modals_controller"
application.register("modals", Modals)

import FaqSearch from "../controllers/faq_search_controller"
application.register("faq-search", FaqSearch)

import Decks from "../controllers/decks_controller"
application.register("decks", Decks)

import Cards from "../controllers/cards_controller"
application.register("cards", Cards)

import Recipes from "../controllers/recipes_controller"
application.register("recipes", Recipes)

import InfiniteScroll from "../controllers/infinite_scroll_controller"
application.register("infinite-scroll", InfiniteScroll)

import RankingSwitch from "../controllers/ranking_switch_controller"
application.register("ranking-switch", RankingSwitch)

import Expansions from "../controllers/expansions_controller"
application.register("expansions", Expansions)
