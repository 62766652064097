import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'

export default class extends Controller {

  static values = { rows: { type: Number, default: 1 } }

  connect() {
    this._setContents()
  }

  /* setup contents */
  _setContents() {

    let swiper = new Swiper('.slide-contents .swiper', {
      slidesPerView: "auto",
      speceBetween: 40,
      loop: false,
      centeredSlides: false,
      freeMode: {
        enabled: false,
        sticky: true,
      },
      grabCursor: true,
    })

  }
}
