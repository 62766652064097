import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  close(e) {
    if  (  ( e.target.dataset != 'undefined' && e.target.dataset instanceof Object && e.target.dataset.type != 'undefined' && e.target.dataset.type == 'overlay')
          || (e.detail instanceof Object && e.detail.type == 'overlay') ) {
      this.modalTarget.parentNode.textContent = ""
    }
  }
}
