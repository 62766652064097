import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["show", "turn" ,"image"]

  elastic(e) {
    e.currentTarget.nextElementSibling.classList.toggle('u-display-none')
  }

  turn(e) {
    e.preventDefault()

    this.imageTarget.src = e.params.path

    this.turnTargets.forEach( ( v, k ) => {
      if ( v == e.currentTarget ) {
        // current elements
        e.params.inactiveClass.split(" ").forEach( ( i ) => {
          v.classList.remove(i)
        })
        e.params.activeClass.split(" ").forEach( ( a ) => {
          v.classList.add(a)
        })
      } else {
        // other elements
        e.params.activeClass.split(" ").forEach( ( a ) => {
          v.classList.remove(a)
        })
        e.params.inactiveClass.split(" ").forEach( ( i ) => {
          v.classList.add(i)
        })
      }
    })
  }

  show(e) {
    e.preventDefault()

    let url = e.currentTarget.href

    fetch(url + "/" + e.params.cardId + '.json')
      .then( res => res.json() )
      .then( json => {
        this.showTarget.innerHTML = json.contents
      })
      .catch( error => {
        console.log(error)
      })
  }
}
